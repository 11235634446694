import React from "react";
import fontStyles from "fontStyles.module.scss";

import LoadingIcon from "atoms/LoadingIcon";
import Label from "molecules/v2/Label";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import LinkText from "atoms/LinkText";
import CopyableText from "atoms/ezPushShared/accountPageShared/CopyableText";
import Separator from "atoms/ezPushShared/Separator";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";

const IOSSettingTitleElement: React.VFC = () => (
  <Label
    label={
      <>
        <span className={fontStyles.section}>
          オプション①　iOS対応手順のご紹介
        </span>
        <span className={fontStyles.boldText}> ※必須設定ではありません。</span>
      </>
    }
  />
);

const OtherOptionListElement: React.VFC<{
  link: string;
}> = ({ link }) => {
  if (!link) {
    return <LoadingIcon />;
  }

  return (
    <VerticalSpreadLayout
      margin={5}
      items={[
        <MultipleLineText
          texts={[
            "iOS対応を行いたい場合は、以下のマニュアルを参考に設定を行ってください。",
            <LinkText text={"iOS対応マニュアル"} link={link} external />,
          ]}
        />,
      ]}
    />
  );
};

const OptoutUrlTitleElement: React.VFC = () => (
  <Label
    label={
      <>
        <span className={fontStyles.section}>
          オプション②　オプトアウトURLのご紹介
        </span>
        <span className={fontStyles.boldText}> ※必須設定ではありません。</span>
      </>
    }
  />
);

const OptoutURLAreaElement: React.VFC = () => (
  <div style={{ border: "1px solid #dddddd" }}>
    <HorizontalLayout
      elements={[
        <div
          style={{
            backgroundColor: "#fff7f1",
            padding: "15px",
            borderRight: "1px solid #dddddd",
          }}
        >
          オプトアウトパラメータ
        </div>,
        <div style={{ backgroundColor: "#ffffff", padding: "15px" }}>
          <CopyableText text={"?ezcx_opt_out=true"} />
        </div>,
      ]}
    />
  </div>
);

const OptoutUrlElement: React.VFC = () => (
  <VerticalSpreadLayout
    margin={15}
    items={[
      <MultipleLineText
        texts={[
          "オプトアウトURLとは、通知許諾済みのエンドユーザーがこのURLをクリックすると、当サービスからの通知を停止できます。",
        ]}
      />,
      <OptoutURLAreaElement />,
      <OptoutDescriptionElement />,
    ]}
  />
);

const OptoutDescriptionElement: React.VFC = () => (
  <VerticalSpreadLayout
    margin={10}
    items={[
      <span className={fontStyles.boldText}>利用方法</span>,
      <MultipleLineText
        texts={[
          "1. 任意のURLに上記オプトアウトパラメータを付けてください。",
          "2. 上記1.で指定したページにサービス専用タグを設置してください。",
          "3. エンドユーザーに上記1. のオプトアウトパラメータつきのURLをお伝えください。",
          "4. エンドユーザーが上記3. のURLをクリックして、指定したページに遷移するとオプトアウトが行われます。",
          "　※オプトアウトが成功すると「(許諾ドメイン)からのWebPush通知は停止しました。」というポップアップが表示されます。",
          "　※オプトアウト済みの場合は、何も表示されません。",
        ]}
      />,
    ]}
  />
);

const OptionConfiguration: React.VFC = () => {
  return (
    <VerticalSpreadLayout
      margin={40}
      items={[
        <VerticalSpreadLayout
          margin={5}
          items={[
            <IOSSettingTitleElement />,
            <OtherOptionListElement link="https://doc.target-push.com/ios.pdf" />,
          ]}
        />,
        <Separator />,
        <VerticalSpreadLayout
          margin={5}
          items={[<OptoutUrlTitleElement />, <OptoutUrlElement />]}
        />,
      ]}
    />
  );
};

export default OptionConfiguration;
